<template>
    <div class="pictures">
        <div class="title">
            <div>现场图片</div>
            <img src="../../../../assets/u25.svg" alt="" />
            <div class="line"></div>
        </div>
        <div class="content">
            <a-image
                height="150px"
                v-for="item in pictures"
                :key="item"
                :src="`${baseURL}/web/mes-file-info/download/${item}`"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, nextTick, ref, watch } from "vue";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: ["pictures"],
    setup() {
        return {
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.pictures {
    grid-area: g2;
    padding: 8px;
    .title {
        color: #fff;
        position: relative;
        .line {
            background: rgba(255, 255, 255, 0.2);
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 6px;
        }
    }
    .content {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 8px;
        max-height: 500px;
        overflow-y: scroll;
    }
}
</style>
