<template>
    <div class="workrder">
        <div class="title">
            <div>事件列表</div>
            <img src="../../../../assets/u25.svg" alt="" />
            <div class="line"></div>
        </div>
        <Table :columns="columns" :data="data" :striped="true" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Table from "../../Table.vue";
const columns = [
    {
        title: "事件名称",
        dataIndex: "eventName",
    },
    {
        title: "事件状态",
        dataIndex: "statusDesc",
    },
    {
        title: "事件等级",
        dataIndex: "eventLevelDesc",
    },
    {
        title: "关联工单号",
        dataIndex: "workOrderCode",
    },
    {
        title: "上报时间",
        dataIndex: "createdTime",
    },
    {
        title: "问题描述",
        dataIndex: "description",
    },
    {
        title: "处理方案",
        dataIndex: "processingScheme",
    },
    {
        title: "上报人",
        dataIndex: "creatorDisplayName",
        width: 100,
    },
];
export default defineComponent({
    props: ["data"],
    components: {
        Table,
    },
    setup() {
        return {
            columns,
        };
    },
});
</script>

<style lang="less">
::-webkit-scrollbar {
    display: none;
}
.workrder {
    height: 60vh;
    overflow: hidden;
    grid-area: g1;
    padding: 8px;
    position: relative;
    background: rgba(0, 0, 255, 0.09803921568627451);
    .title {
        color: #fff;
        position: relative;
        .line {
            background: rgba(255, 255, 255, 0.2);
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 6px;
        }
    }
}
</style>
