<template>
    <div class="container">
        <div class="bankname">
            <img src="../../../assets/left.svg" alt="" />
            <span class="name">采日车间异常看板</span>
            <img src="../../../assets/right.svg" alt="" />
        </div>
        <EventList :data="data" />
        <Pictures :pictures="pictures" />
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Pictures from "./components/pictures.vue";
import EventList from "./components/eventList.vue";
import { apiBigScreenExceptionPanel } from "@/api";
export default defineComponent({
    components: {
        Pictures,
        EventList,
    },
    setup() {
        const state = reactive({
            data: null,
            pictures: [],
        });

        const getData = async () => {
            let res = await apiBigScreenExceptionPanel();
            if (res.status === "SUCCESS") {
                state.data = res.data;
                state.pictures = res.data.map(item => item.eventPicIds).flat();
            }
        };
        getData();

        setInterval(() => getData(), 60000);

        return {
            ...toRefs(state),
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
        display: none;
    }
.bankname {
    height: 80px;
    text-align: center;
    font-size: 30px;
    padding: 20px 0;
    box-sizing: border-box;
    font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC", sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 36px;
    color: #ffffff;
    grid-area: g0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 100%;
        flex: 1;
        width: 0;
    }
    .name {
        display: inline-block;
        flex: 1;
    }
}
.container {
    overflow: scroll;
    height: 100vh;
    min-width: 1500px;
    min-height: 100vh;
    background-image: url("../../../assets/back.png");
    background-size: 100% 100vh;
    background-repeat: no-repeat;
    display: grid;
    grid-template-areas:
        "g0 g0"
        "g1 g1"
        "g2 g2";
    grid-gap: 8px;
    padding: 8px;
}
</style>
